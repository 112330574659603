<template>
  <header class="header">
    
    <div class="header-content max-container">
      <div class="header-content__nav">
        <menu-component />
        <div class="header-content__nav--img">
          <a href="/">
            <img class="header-content__img" src="../assets/images/logo.png" alt="wager connection" />
          </a>
        </div>
        <div class="button-colapse">
          <span @click="toggleCollapse" class="btn btn-primary material-symbols-outlined">
          login
          </span>
        </div>
      </div>
      <div class="header-content__cta">
          <!-- Desktop form -->
          <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="Username" name="account" id="account">
            <input class="login__form--inputs" type="password" name="password" placeholder="Password" id="password">
            
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">classic</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
      </div>
    </div>
    <div class="max-container">
      <!-- Mobile form -->
      <div>
          <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
                <div class="header-inputs">
                  <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
                  <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">
                </div>
                <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
                <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
                <div class="header-buttons">
                  <button class="btn-login secondary-button secondary-button__classic" type="submit">CLASSIC</button>
                  <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
                </div>
          </form>
      </div>
    </div>
    <div class="list-menu">
      <ul>
        <li class="list-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue'
import MenuComponent from '@/components/menu.vue'

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
    MenuComponent
  },
  data() {
    return {
      isCollapsed: true, // Estado inicial colapsado
      routes: [
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            name       :  'parlay calculator',
            route      :  'parlay-rules'
          } 
      ]
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  setup() {
    const DGS_SITEID = 19;
    const backendUrl = "Luckybets.mx";
    return {
      DGS_SITEID,
      backendUrl,
    }
  },
};
</script>
