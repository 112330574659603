<template>
  <footer class="footer">
    <div class="footer-content">
      <p>© 2024 LuckyBets.mx  - Bet Smart, Win Big, Live Lucky!</p>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {

    },
  };
</script>
